import {FC} from 'react';
import {Routes} from 'react-router';
import styled from 'styled-components';

import {useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/core/use_shared_routes';

import {MainPage} from '@src/components/main_page';
import {NavBar} from '@src/components/nav_bar';

export const App: FC = () => {
  const seo = {
    title: 'Restaurant',
    description: 'Une cuisine généreuse et simple élaboré à base de produits frais',
  };

  return (
    <Wrapper
      // eslint-disable-next-line react/forbid-component-props
      id="top"
    >
      <NavBar />
      <Routes>
        {useSharedRoutes({seo}, {DisabledComponent: MainPage})}
        {useRoute('*', MainPage, {seo})}
      </Routes>
    </Wrapper>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
`;
